import {Component, OnInit} from '@angular/core';
import {SessionService} from '../../services/session.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
})
export class LogoutComponent implements OnInit {

  constructor(private sessionService: SessionService) {
  }

  ngOnInit(): void {
    this.sessionService.logout();
  }

}
