import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http'
import {Observable} from 'rxjs'
import {tap} from 'rxjs/operators'
import {Injectable} from '@angular/core'

const TIMEBOX_HEADER_PREFIX = 'x-tb-'

@Injectable()
export class TimeboxHeaderInterceptor implements HttpInterceptor {

    private timeboxHeaders = {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        request = request.clone({
            setHeaders: this.timeboxHeaders
        })

        return next.handle(request)
            .pipe(
                tap(event => {
                    if (event instanceof HttpResponse) {
                        this.timeboxHeaders = event.headers.keys()
                            .filter((value) => value.startsWith(TIMEBOX_HEADER_PREFIX))
                            .reduce((map, key: string) => {
                                map[key] = event.headers.get(key)
                                return map
                            }, this.timeboxHeaders)
                    }
                }, _ => {
                })
            )
    }
}
