import { Injectable } from '@angular/core'
import { UserService } from './user.service'
import { GraphqlCollectorService } from '../http/graphql-collector.service'
import { createVariable } from '../../util/graphql-executor'
import {BehaviorSubject, EMPTY, Observable} from 'rxjs'
import { map } from 'rxjs/operators'


@Injectable({
  providedIn: 'root'
})
export class ReleaseNotesService {

  constructor(private userService: UserService,
              private graphqlCollectorService: GraphqlCollectorService) {
    this.userService.me$.subscribe(me => {
      if (me !== undefined) {
        this.allReleaseNotes$.subscribe(releaseNote => {
          this._allReleaseNotes$.next(releaseNote)
        })
      }
    })
  }
  private _allReleaseNotes$ = new BehaviorSubject<ReleaseNote[]>([])

  get allRelevantReleaseNotes$(): Observable<ReleaseNote[]> {
    return this._allReleaseNotes$
  }

  get allReleaseNotes$(): Observable<ReleaseNote[]> {
    const me = this.userService.me
    if (!me) {
      return EMPTY
    }
    return this.graphqlCollectorService.query(
      ReleaseNotesService.allRelevantReleaseNotesForUserRole(me.id)
    ).pipe(
      map((result: any[]) => {
        return result.map((change) => {
          return {
            id: change.id,
            title: change.title,
            description: change.description,
            affectedRoles: change.affectedRoles,
            version: change.version,
            releaseDate: change.releaseDate
          } as ReleaseNote
        })
      })
    )
  }

  private static allRelevantReleaseNotesForUserRole(userId: number) {
    return {
      variables: [
        createVariable('userId', 'Int!', userId)
      ],
      function: 'releaseNotes',
      fieldBody: RELEASE_NOTES
    }
  }
}

export interface ReleaseNote {
  id: number,
  title: string,
  description: string,
  affectedRoles: string[],
  version: string,
  releaseDate: string
}

export const RELEASE_NOTES =
  `
    id,
    title,
    description,
    affectedRoles,
    version,
    releaseDate,
 `
