<div>
  <div class="textArea">
    <br>
    Hallo du hast eine Frage, möchtest uns Kritik, Anregungen oder einen Fehler mitteilen?
    <br>
    Dann schreib uns doch eine Email :-)
    <br>
    <br>
    dein Basisteam
    <br>
    <br>
  </div>
  <div class="mailtoContainer">
    <a class="mailto" href="{{mail}}">hier Feedback geben</a>
  </div>
</div>
<br>
<button (click)="dismiss()" class="clickable">Schließen</button>
