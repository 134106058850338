  <div class="modal-container" lang="de">
    <h2>Verbesserungen der Timebox <span>(Version: {{releaseNotes[0].version}})</span></h2>
    <div class="modal-body">
      <ul>
        <li *ngFor="let note of releaseNotes">
          <h3>{{ note.title }} <span>(Version: {{note.version}})</span></h3>
          <p>{{ note.description }}</p>
        </li>
      </ul>
    </div>
    <div class="modal-footer">
      <button (click)="onClose()">Hinweis schließen</button>
    </div>
  </div>
