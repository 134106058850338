import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {RouterModule, Routes} from '@angular/router'
import {ForbiddenComponent} from './forbidden/forbidden.component'
import {NoAccessComponent} from './no-access/no-access.component'
import {LogoutComponent} from './logout/logout.component'


const routes: Routes = [
  {path: 'no-access', component: NoAccessComponent},
  {path: 'forbidden', component: ForbiddenComponent},
  {path: 'logout', component: LogoutComponent}
]


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  declarations: [NoAccessComponent, ForbiddenComponent, LogoutComponent],
  exports: [NoAccessComponent, ForbiddenComponent]
})
export class SecurityModule {
}
