import {ErrorHandler, Injectable, NgZone} from '@angular/core'
import {AzureLogging} from './azure-logging.service'
import {Router} from '@angular/router'

@Injectable()
export class AdvancedErrorHandlingService extends ErrorHandler {

  constructor(private readonly router: Router,
              private readonly ngZone: NgZone) {
    super()
  }

  handleError(error): void {
    try {
      super.handleError(error)
      AzureLogging.logError(error)
    } catch (error) {
      console.error('An error occurred by sending an error to AzureLogging:', error)
    }

    if (error === 'ChunkLoadError') {
      this.ngZone.run(() => {
        this.router.navigateByUrl('/forbidden').then()
      })
    } else {
      console.error('An error occurred:', error)
    }
  }
}
