import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http'
import {BehaviorSubject, Observable, throwError} from 'rxjs'
import {catchError, retry} from 'rxjs/operators'
import {ErrorService} from '../error.service'
import {Injectable} from '@angular/core'
import {SessionService} from '../session.service'
import {RequestFailsafeService} from '../request-failsafe.service'
import {Router} from '@angular/router'
import {UserService} from '../repository/user.service'

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private errorService: ErrorService,
              private failSafeService: RequestFailsafeService,
              private router: Router,
              private userService: UserService,
              private sessionService: SessionService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
      if (this.userService.me !== undefined) {
      return next.handle(request)
        .pipe(
          retry(0),
          catchError((error: HttpErrorResponse) => {
            if (error.status === 401) {
              const savedRequest = request.clone()
              this.failSafeService.addRequestToFailedList(savedRequest)
              this.sessionService.showLoginDialog()
            } else if (error.status === 403) {
              this.router.navigateByUrl('forbidden')
            } else {
              this.errorService.showErrorDialog(buildErrorMessage(error))
            }
            return new BehaviorSubject(error).asObservable()
          })
        )
    } else {
      return next.handle(request).pipe(
        retry(0),
        catchError((error: HttpErrorResponse) => {
          // show error dialog when server is down and after it has been closed once
          if (error.status === 0) {
            this.errorService.showErrorDialog(buildErrorMessage(error))
          }
          return throwError(error)
        })
      )
    }
  }
}

function buildErrorMessage(error: HttpErrorResponse): string {
  if (error.error instanceof ErrorEvent) {
    // client-side error
    return `Error: ${error.error.message}`
  } else {
    // server-side error
    if (error.status === 0) {
      return `Die Kommunikation mit dem Server ist fehlgeschlagen\n`
    } else {
      return `Error Code: ${error.status}\nMessage: ${error.message}\n`
    }
  }
}
