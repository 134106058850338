import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core'
import {AppComponent} from './app.component'
import {TimeboxHeaderInterceptor} from './services/http/timebox-header-interceptor'
import {AppRoutingModule} from './app-routing.module'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import {RequestIdHttpInterceptor} from './services/http/request-id-http-interceptor'
import {SecurityModule} from './security/security.module'
import {SharedComponentsModule} from './shared-components/shared-components.module'
import {HeaderModule} from './header/header.module'
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog'
import {MatIconModule} from '@angular/material/icon'
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner'
import {AdvancedErrorHandlingService} from './services/advanced-error-handling.service'
import {HttpErrorInterceptor} from './services/http/http-error-interceptor'
import {MatTreeModule} from '@angular/material/tree'
import {BrowserModule} from '@angular/platform-browser'
import {MsalModule, MsalRedirectComponent} from '@azure/msal-angular'
import {MsalConfigDynamicModule} from './msal-dynamic.module'
import {MatButtonModule} from '@angular/material/button'
import {RouterModule} from '@angular/router'

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SecurityModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedComponentsModule,
    MatDialogModule,
    HeaderModule,
    MsalModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatTreeModule,
    MatButtonModule,
    MsalConfigDynamicModule.forRoot(),
    RouterModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimeboxHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestIdHttpInterceptor,
      multi: true,
    },
    {
      provide: MatDialogRef, useValue: {}
    },
    {
      provide: MAT_DIALOG_DATA, useValue: {}
    },
    {
      provide: ErrorHandler, useClass: AdvancedErrorHandlingService
    },
  ],
  exports: [SharedComponentsModule],
  bootstrap: [AppComponent, MsalRedirectComponent],
})

export class AppModule {
}
