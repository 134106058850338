import {Injectable} from '@angular/core'
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http'
import {v4 as uuidv4} from 'uuid'
import {Observable} from 'rxjs'

@Injectable()
export class RequestIdHttpInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const req = request.clone({
      headers: request.headers.set('x-request-id', uuidv4())
    })
    return next.handle(req)
  }

}
