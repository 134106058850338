<div>
  Client Version: <span>{{timeboxAppVersion}}</span>
  <br>
  Client Build Version: <span>{{timeboxAppBuildVersion}}</span>
</div>
<div>
  <br>
  Server Version: <span>{{serverVersion}}</span>
  <br>
  Server Commit: <span>{{serverCommit}}</span>
  <br>
  Build Number: <span>{{serverBuild}}</span>
  <br>
  Build Date: <span>{{serverDate}}</span>
  <br>
</div>
<br>
<button (click)="dismiss()" class="clickable">Close</button>
