import {ModuleWithProviders, NgModule} from '@angular/core'
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard, MsalGuardConfiguration,
  MsalInterceptor, MsalInterceptorConfiguration,
  MsalModule,
  MsalService
} from '@azure/msal-angular'
import {HTTP_INTERCEPTORS} from '@angular/common/http'
import { environment } from '../environments/environment'
import {InteractionType, IPublicClientApplication, PublicClientApplication} from '@azure/msal-browser'
import {RouterModule} from '@angular/router'


// ---- Configuration MSAL ----
const IS_INTERNET_EXPLORER  = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1
export const MSAL_AUTHORITY = 'https://login.microsoftonline.com/7849ae70-f969-4dae-a3eb-fcefa4b60df1'

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      // 'Application (client) ID' of app registration in Azure portal - this value is a GUID
      clientId: environment.clientId, // Application (client) ID from the app registration
      // Full directory URL, in the form of https://login.microsoftonline.com/<tenant-id>
      authority: MSAL_AUTHORITY,
      // Full redirect URL, in form of http://localhost:3000
      redirectUri: `${window.location.origin}/postlogin`, // This is your redirect URI
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: IS_INTERNET_EXPLORER , // Set to true for Internet Explorer 11
    },
    system: {
      loggerOptions: {
        loggerCallback: () => {
        },
        piiLoggingEnabled: false,
      },
    },
  })
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect, // MSAL Guard Configuration
  }
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>()
  protectedResourceMap.set(`${environment.serverUrl}/*`, ['openid'])

  return {
    interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
    protectedResourceMap
  }
}

@NgModule({
  providers: [],
  imports: [MsalModule, RouterModule]
})
export class MsalConfigDynamicModule {

  static forRoot(): ModuleWithProviders<MsalConfigDynamicModule> {
    return {
      ngModule: MsalConfigDynamicModule,
      providers: [
        {
          provide: MSAL_INSTANCE,
          useFactory: MSALInstanceFactory,
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: MSALGuardConfigFactory,
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: MSALInterceptorConfigFactory,
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          multi: true
        }
      ]
    }
  }
}
