import {Component, OnInit} from '@angular/core'
import {ReleaseNote, ReleaseNotesService} from '../../../services/repository/release-notes.service'
import {MatDialog} from '@angular/material/dialog'
import {UserReleaseNotesDialogComponent} from '../user-release-notes-dialog/user-release-notes-dialog.component'

@Component({
  selector: 'app-user-release-notes',
  templateUrl: './user-release-notes.component.html',
  styleUrls: ['./user-release-notes.component.scss']
})
export class UserReleaseNotesComponent implements OnInit {
  public releaseNotes: ReleaseNote[] = []

  constructor(private releaseNotesService: ReleaseNotesService, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.releaseNotesService.allRelevantReleaseNotes$.subscribe((data) => {
      this.releaseNotes = data
      if (this.releaseNotes.length > 0) {
        this.openDialog()
      }
    })
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(UserReleaseNotesDialogComponent, {
      data: this.releaseNotes
    })
  }
}
