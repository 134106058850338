import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {ReleaseNote} from '../../../services/repository/release-notes.service'

@Component({
  selector: 'app-user-release-notes-dialog',
  templateUrl: './user-release-notes-dialog.component.html',
  styleUrls: ['./user-release-notes-dialog.component.scss']
})
export class UserReleaseNotesDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public releaseNotes: ReleaseNote[],
    public dialogRef: MatDialogRef<UserReleaseNotesDialogComponent>) {
  }

  onClose(): void {
    this.dialogRef.close()
  }
}
