<div #notificationBell class="notification-bell">
  <button (click)="toggleNotification()"
          [title]="notifications.length==0?'Keine Nachrichten':'Nachrichten anzeigen'"
          class="notification-button icon-button-large"
          mat-fab>
    <mat-icon [matBadgeHidden]="showNotification || notifications.length == 0"
              [svgIcon]="iconString"
              class="notification"
              color="{{notificationBellThemeColor}}"
              matBadge="{{notifications.length}}"
              matBadgeColor="accent"
              matBadgeOverlap="true"
              matBadgePosition="below after"
              matBadgeSize="small"
    >
    </mat-icon>
  </button>

  <mat-card appearance="outlined" *ngIf="showNotification" class="notification-messages">
    <mat-card-title>
      <div class="title-container">
          <mat-icon [style.color]="notificationBellColor" [svgIcon]="iconString" class="notification cardTitleElement">
          </mat-icon>
        <div
          class="cardTitleElement cardTitleElementText">{{currentNotification.notificationStateData.titleMessage}}</div>
        <div class="cardTitleElement cardTitleElementRight">
          <app-pager (currentPage)="pageChanged($event)"
                     [numberOfPages]="notifications.length"
                     [showNumberOfPages]="notifications.length > 1"
                     class="pager">
          </app-pager>
        </div>
      </div>
    </mat-card-title>
    <mat-card-content>
      <div [innerHTML]="currentNotification.message"
           [style.background-color]="currentNotification.notificationStateData.messageBackgroundColor"
           class="cardContentContainer"></div>
    </mat-card-content>
    <mat-card-actions class="cardFooterContainer" align="end">
      <button (click)="hideNotification()" class="cardFooterElement button-cancel" mat-button>Später</button>
      <button (click)="navigateToSubmitView()"
              [routerLink]="'/' + submitRoute"
              class="cardFooterElement button-save"
              color="primary"
              mat-flat-button>
        Zum Nachweis
      </button>
    </mat-card-actions>
  </mat-card>
</div>
