import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core'
import {Subscription} from 'rxjs'
import {
  NO_NOTIFICATION_STATE,
  Notification,
  NotificationsService,
  REOPENDED_NOTIFICATION_STATE
} from '../../services/notifications.service'
import {TimeNavigationService} from '../../services/time-navigation.service'
import {CustomIcon} from '../../services/custom-icons.service'
import {Router} from '@angular/router'
import {SUBMIT_ROUTE} from '../../app-routing.module'

@Component({
  selector: 'app-notification-bell',
  templateUrl: './notification-bell.component.html',
  styleUrls: ['./notification-bell.component.scss']
})
export class NotificationBellComponent implements OnInit, OnDestroy {

  @ViewChild('notificationBell', {static: true}) notificationBellReference: ElementRef

  showNotification = false
  iconString = CustomIcon.NOTIFICATIONS_NONE as string
  notificationBellThemeColor = 'warn'
  notificationBellColor = '#006e96'

  notifications: Notification[] = []
  currentNotification: Notification = null

  submitRoute = SUBMIT_ROUTE
  private notificationsSubscription: Subscription

  constructor(private notificationsService: NotificationsService,
              private timeNavigationService: TimeNavigationService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.notificationsSubscription = this.notificationsService.notifications$.subscribe(notifications => {
      this.notifications = notifications
      this.computeStyleData()
      this.setCurrentNotification(0)
    })
  }

  ngOnDestroy(): void {
    this.notificationsSubscription.unsubscribe()
  }

  pageChanged(pageNumber: number): void {
    this.setCurrentNotification(pageNumber - 1)
  }

  navigateToSubmitView(): void {
    const date = new Date(this.currentNotification.yearMonth.year, this.currentNotification.yearMonth.month)
    this.timeNavigationService.navigateTo(date)
    this.router.navigate(['/' + SUBMIT_ROUTE]).then()
    this.hideNotification()
  }

  toggleNotification(): void {
    this.showNotification = !((!this.canShowNotification()) || this.showNotification)
  }

  hideNotification(): void {
    this.showNotification = false
  }

  canShowNotification(): boolean {
    return this.currentNotification != null
  }

  setCurrentNotification(index: number): void {
    this.currentNotification = (this.notifications.length > 0) ? this.notifications[index] : null
    this.computeStyleData()
  }

  containsCriticalNotification(): boolean {
    console.log('--------------------------------------------------------------------------------')
    console.log(REOPENDED_NOTIFICATION_STATE)

    return this.notifications.some(value =>
    {
      console.log(value)
      console.log(value.notificationStateData === REOPENDED_NOTIFICATION_STATE)
      return value.notificationStateData === REOPENDED_NOTIFICATION_STATE
    })
  }

  @HostListener('document:click', ['$event'])
  clickout(event): void {
    if (this.showNotification && !this.notificationBellReference.nativeElement.contains(event.target)) {
      this.hideNotification()
    }
  }

  private computeStyleData(): void {
    const bellIconData = (this.currentNotification) ? this.currentNotification.notificationStateData : NO_NOTIFICATION_STATE
    this.iconString = bellIconData.icon
    this.notificationBellThemeColor = bellIconData.themeColor
    this.notificationBellColor = bellIconData.color
  }
}
