import {Injectable} from '@angular/core'
import {LocalUserPropertiesService} from './local-user-properties.service'
import {HttpClient, HttpRequest} from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class RequestFailsafeService {

  private readonly PROPERTY_KEY = 'saved_failed_requests'

  constructor(
    private localPropertyService: LocalUserPropertiesService,
    private http: HttpClient
  ) {
  }

  addRequestToFailedList(request: HttpRequest<any>) {
    let listOfSavedRequests: Array<any> = []
    this.localPropertyService.getProperty(this.PROPERTY_KEY, []).then(result => {
      listOfSavedRequests = result
      listOfSavedRequests.push(request)
      this.localPropertyService.setProperty(this.PROPERTY_KEY, listOfSavedRequests)
    })
  }

  reDoFailedRequests() {
    let listOfFailedRequests: Array<HttpRequest<any>> = []
    this.localPropertyService.getProperty(this.PROPERTY_KEY, []).then(result => {
      listOfFailedRequests = result

    })

  }

}
