import {Injectable} from '@angular/core'
import {BehaviorSubject, Observable} from 'rxjs'
import {DateUtils, MONTH_NAMES} from '../util/date-utils'
import {LocalTimeEntriesService} from './repository/local-time-entries.service'
import {Month, SubmitMonthService, SubmitType} from './repository/submit-month.service'
import {CustomIcon} from './custom-icons.service'

export const NO_NOTIFICATION_STATE: NotificationStateData = {
  icon: CustomIcon.NOTIFICATIONS_NONE,
  themeColor: 'primary',
  color: '#006e96',
  messageBackgroundColor: '',
  titleMessage: ''
}

export const UNSUBMITTED_NOTIFICATION_STATE: NotificationStateData = {
  icon: CustomIcon.NOTIFICATIONS_ACTIVE,
  themeColor: 'warn',
  color: '#F0D24B',
  messageBackgroundColor: '#FAF4D4',
  titleMessage: 'Nachweis nicht eingereicht'
}

export const REOPENDED_NOTIFICATION_STATE: NotificationStateData = {
  icon: CustomIcon.NOTIFICATIONS_ACTIVE,
  themeColor: 'warn',
  color: '#EB193C',
  messageBackgroundColor: '#F7DCE0',
  titleMessage: 'Nachweis wurde zurückgeschickt'
}

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private submitMonthService: SubmitMonthService,
              private localTimeEntriesService: LocalTimeEntriesService) {
    this.updateNotifications()
    this.localTimeEntriesService.currentMonth$.subscribe(() => {
      this.updateNotifications()
    })

    this.submitMonthService.currentMonthSubmitted$.subscribe(() => {
      this.updateNotifications()
    })
    this.submitMonthService.allSubmitted$.subscribe(() => {
      this.updateNotifications()
    })
  }

  private _notifications$ = new BehaviorSubject<Notification[]>([])

  get notifications$(): Observable<Notification[]> {
    return this._notifications$
  }

  private updateNotifications() {
    const months = this.submitMonthService.allSubmitted.sort((a, b) => {
      return DateUtils.isBeforeDate(DateUtils.stringToDate(a.month), DateUtils.stringToDate(b.month)) ? 1 : -1
    })
    if (months != undefined && months.length > 0) {
      const notifications: Notification[] = []
      this.addNonApprovedMonthsToNotificationList(notifications, months)
      this.addMonthsToSubmitToNotificationList(notifications, months)
      this._notifications$.next(notifications)
    }
  }

  private addMonthsToSubmitToNotificationList(notifications: Notification[], months: Month[]) {
    const today = DateUtils.today
    months
      .filter(month => {
        return month.type == SubmitType.Open
      })
      .filter(month => {
        const monthDate = DateUtils.stringToDate(month.month)
        return !DateUtils.isInMonth(monthDate, today)
          && DateUtils.isBeforeDate(monthDate, today)
      })
      .map(month => {
        const date = DateUtils.stringToDate(month.month)
        return {
          year: date.getFullYear(),
          month: date.getMonth()
        } as YearMonth
      })
      .forEach(monthToSubmit => {
        notifications.push(this.convertToUnsubmittedMonthNotification(monthToSubmit))
      })
  }

  private addNonApprovedMonthsToNotificationList(notifications: Notification[], months: Month[]) {
    const nonApprovedMonths = months.filter(month => {
      return month.type == SubmitType.Reopened
    })
    nonApprovedMonths.forEach(nonApprovedMonth => {
      const date = DateUtils.stringToDate(nonApprovedMonth.month)
      notifications.push(this.convertToReopenedMonthNotification(nonApprovedMonth.comment, {
        year: date.getFullYear(),
        month: date.getMonth()
      }))
    })
  }

  private convertToReopenedMonthNotification(comment: string, yearMonth: YearMonth): Notification {
    return {
      message: `Ihr Nachweis für den Monat <a routerLink="/overview">${MONTH_NAMES[yearMonth.month]} ${yearMonth.year}</a> wurde zurückgeschickt.<br /><b>Begründung: <br />${comment}</b>`,
      yearMonth: {
        year: yearMonth.year,
        month: yearMonth.month
      },
      notificationStateData: REOPENDED_NOTIFICATION_STATE
    }
  }

  private convertToUnsubmittedMonthNotification(yearMonth: YearMonth): Notification {
    return {
      message: `Bitte denke daran, den Nachweis für den Monat <a [routerLink]="'/overview'">${MONTH_NAMES[yearMonth.month]} ${yearMonth.year}</a> einzureichen`,
      yearMonth: {
        year: yearMonth.year,
        month: yearMonth.month
      },
      notificationStateData: UNSUBMITTED_NOTIFICATION_STATE
    }
  }
}

interface YearMonth {
  year: number,
  month: number
}

export class Notification {
  message: string
  yearMonth: YearMonth
  notificationStateData: NotificationStateData
}

interface NotificationStateData {
  icon: string,
  themeColor: string
  color: string
  messageBackgroundColor: string
  titleMessage: string
}
