import {Component, HostListener, OnDestroy, OnInit} from '@angular/core'
import {Subscription} from 'rxjs'
import {PageLayout, PageLayoutService} from './services/page-layout.service'
import {SessionService} from './services/session.service'
import {hasPermissions, Permission, User, UserService} from './services/repository/user.service'
import {TimeEntryTypeService} from './services/repository/time-entry-type.service'
import {AnyCanDeactivate} from './util/can-deactivate.guard'
import {CustomIconsService} from './services/custom-icons.service'
import {SynchronisationService, SynchronizationStatus} from './services/sync/synchronize-data.service'
import {Router} from '@angular/router'
import {AppFocusService} from './services/app-focus-service.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends AnyCanDeactivate implements OnInit, OnDestroy {

  waitingForLogout = false
  crucialDataIsLoaded = false
  showUi = false
  user: User
  layout: PageLayout
  accessGranted = false
  tabs: TabInfo[]
  private layoutChangeSubscription: Subscription
  private timeEntryTypeSubscription: Subscription
  private userSubscription: Subscription

  // noinspection JSUnusedLocalSymbols
  constructor(private sessionService: SessionService,
              private userService: UserService,
              public timeEntryTypeService: TimeEntryTypeService,
              private layoutService: PageLayoutService,
              private customIconsService: CustomIconsService,
              private synchronisationService: SynchronisationService,
              private router: Router,
              private appFocusService: AppFocusService) {
    super()
    this.customIconsService.registerCustomIcons()
  }

  ngOnInit(): void {
    this.waitingForLogout = false
    this.sessionService.login()
    this.sessionService.loginData$.subscribe(loginData => {
      const email = loginData.email
      if (email != null && email.length > 0) {
        this.showUi = true

        this.userSubscription = this.userService.me$.subscribe(user => {
          this.user = user
          if (user != null) {
            this.accessGranted = this.userService.hasApplicationAccess(user)
          }
          if (this.accessGranted) {
            this.layoutChangeSubscription = this.layoutService.$layoutChange.subscribe(layout => this.layout = layout)
            this.layout = this.layoutService.getCurrentLayout()

            this.startPrefetchingImportantData()
            this.buildTabs()
          }
        })
      }
    })

    this.sessionService.waitingForLogout.subscribe(() => {
      this.waitingForLogout = true
    })
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: FocusEvent): void {
    this.appFocusService.notifyAppFocus()
    this.sessionService.checkIfLoggedIn()
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: FocusEvent): void {
    this.appFocusService.notifyAppBlur()
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe()
    this.timeEntryTypeSubscription.unsubscribe()
    this.layoutChangeSubscription.unsubscribe()
  }


  canDeactivate(): boolean {
    return this.synchronisationService.status === SynchronizationStatus.IS_SYNCHRONIZED
  }

  private buildTabs(): void {
    this.tabs = this.router.config
      .filter(routeConfig => routeConfig.data != null && routeConfig.data.displayName != null)
      .filter(routeConfig => routeConfig.data.disabled == null || !routeConfig.data.disabled)
      .map(routeConfig => {
        const data = routeConfig.data
        return createTabInfo(data.displayName, `/${routeConfig.path}`, data.restrictedBy as Permission[])
      }).filter((tabInfo: TabInfo) => {
        const permissions: Permission[] = tabInfo.requiredPermissions
        return hasPermissions(this.user, permissions)
      })
  }

  private startPrefetchingImportantData(): void {
    this.timeEntryTypeSubscription = this.timeEntryTypeService.all$.subscribe(value => {
      if (value !== undefined) {
        this.crucialDataIsLoaded = true
      }
    })
  }
}

function createTabInfo(displayName: string = '', routeTo: string = '/', requiredPermissions: Permission[] = []): TabInfo {
  return {displayName, routeTo, requiredPermissions}
}

interface TabInfo {
  displayName: string
  routeTo: string
  requiredPermissions: Permission[]
}
