<div *ngIf="this.userService.me$ | async as user" class="user-info">
  <div [matMenuTriggerFor]="userMenu" tabindex="0" class="clickable" (keydown.enter)="triggerUserMenu($event)" (click)="setFocusOnFirstElement()">
    <div *ngIf="!photoUrl" class="image">
      <div class="fallback">
            <span class="user-name big">
              {{user.firstName.substring(0, 1)}}{{user.lastName.substring(0, 1)}}
            </span>
      </div>
    </div>
    <div *ngIf="photoUrl">
      <img [src]="photoUrl" alt="user image" class="image">
    </div>
  </div>
  <mat-menu #userMenu="matMenu" class="app-menu" (keydown)="onKeyDown($event)">
    <div class="menu-title">
      {{user.firstName}} {{user.lastName}}
      <div class="user-mail">{{user.adRef}}</div>
    </div>
    <div *ngFor="let b of buttonList">
      <button id="{{b.id}}" (click)="b.clickFunction(b.id)" (keydown)="onKeyDown($event)" tabindex="0"
              mat-menu-item>{{b.displayText}}</button>
    </div>
    <div class="separator"></div>
    <button (click)="logout()" (keydown)="onKeyDown($event)" mat-menu-item tabindex="0">{{logoutLabel}}</button>
  </mat-menu>
</div>

