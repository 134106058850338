import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {User, UserService, toUserEntry} from '../../services/repository/user.service';
import {SessionService} from '../../services/session.service';
import {MatMenuTrigger} from '@angular/material/menu';
import {MatSnackBar} from '@angular/material/snack-bar';
import {VersionInfoSnackbarComponent} from '../version-info-snackbar/version-info-snackbar.component';
import {FeedbackSnackbarComponent} from '../feedback-snackbar/feedback-snackbar.component';
import {
  UsersEditDialogComponent,
  UsersEditDialogType
} from '../../basedata/users-edit-dialog/users-edit-dialog.component';
import {DialogReturnType} from '../../shared-components/dialog-return-type';
import {MatDialog} from '@angular/material/dialog';
import {first} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit, OnDestroy {

  readonly buttonList: UserInfoButton[] = [
    {
      id: 'userSettingsButton',
      displayText: 'Benutzereinstellungen',
      clickFunction: (id) => this.showUserSettings(id)
    },
    {
      id: 'infoButton',
      displayText: 'Entwicklerinformationen',
      clickFunction: (id) => this.showDevInfo(id)
    },
    {
      id: 'feedbackButton',
      displayText: 'Feedback',
      clickFunction: (id) => this.showFeedbackInfo(id)
    }
  ];

  readonly logoutLabel = 'Abmelden';

  @ViewChild(MatMenuTrigger) menu: MatMenuTrigger;
  photoUrl: string = undefined;
  private meSubscription: Subscription;

  constructor(public userService: UserService,
              private sessionService: SessionService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog) {
  }

  async ngOnInit(): Promise<void> {
    this.userService.pictureOfMe$.subscribe(value => {
      this.photoUrl = value;
      if (this.photoUrl.length === 0) {
        this.photoUrl = undefined;
      }
    });
  }

  onKeyDown(event: KeyboardEvent): any {
    if (event.code === 'Tab') {
      event.stopPropagation();
      const buttonLabel = (event.target as HTMLElement).innerText;
      if (
        (event.shiftKey && buttonLabel === this.buttonList[0].displayText) ||
        (!event.shiftKey && buttonLabel === this.logoutLabel)
      ) {
        return false;
      }
    }
  }

  triggerUserMenu(event): void {
    this.menu.openMenu();
    this.setFocusOnFirstElement();
    event.preventDefault();
  }

  logout(): void {
    this.sessionService.logout();
  }

  showUserSettings(infoButtonId: string): void {
    document.getElementById(infoButtonId).blur();
    this.menu.closeMenu();
    this.meSubscription = this.userService.me$//
      .pipe(first())//
      .subscribe((me) => {
        const oldEmail = me.email;
        const dialog = this.dialog.open(UsersEditDialogComponent, {
          data: {user: toUserEntry(me), type: UsersEditDialogType.Snackbar}
        });
        dialog.afterClosed().subscribe(result => {
          if (result?.dialogAction === DialogReturnType.SAVE) {
            const resultUser = result.userData as User;
            const newEmail = resultUser.email;
            if (oldEmail !== newEmail) {
              this.userService.editEmail(newEmail);
            }
          }
        });
      });
  }

  showDevInfo(infoButtonId: string): void {
    document.getElementById(infoButtonId).blur();
    this.menu.closeMenu();
    this.snackBar.openFromComponent(VersionInfoSnackbarComponent);
  }

  showFeedbackInfo(feedbackButtonId: string): void {
    document.getElementById(feedbackButtonId).blur();
    this.menu.closeMenu();
    this.snackBar.openFromComponent(FeedbackSnackbarComponent);
  }


  setFocusOnFirstElement(): void {
    document.getElementById(this.buttonList[0].id).focus();
  }

  ngOnDestroy(): void {
    this.meSubscription.unsubscribe();
  }

}

interface UserInfoButton {
  id: string;
  displayText: string;
  clickFunction: (buttonId: string) => void;
}
