import {Component} from '@angular/core';
import {SessionService} from '../../services/session.service';

@Component({
  selector: 'app-no-access',
  templateUrl: './no-access.component.html',
  styleUrls: ['./no-access.component.scss']
})
export class NoAccessComponent {

  constructor(private sessionService: SessionService) {
  }

  logout(): void {
    this.sessionService.logoutNotAuthorizedUser();
  }

}
