import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserInfoComponent} from './user-info/user-info.component';
import {VersionInfoSnackbarComponent} from './version-info-snackbar/version-info-snackbar.component';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {NotificationBellComponent} from './notification-bell/notification-bell.component';
import {RouterModule} from '@angular/router';
import {SharedComponentsModule} from '../shared-components/shared-components.module';
import {FeedbackSnackbarComponent} from './feedback-snackbar/feedback-snackbar.component';
import {BasedataModule} from '../basedata/basedata.module';
import { UserReleaseNotesComponent } from './user-release-notes-modal/user-release-notes/user-release-notes.component'
import { UserReleaseNotesDialogComponent } from './user-release-notes-modal/user-release-notes-dialog/user-release-notes-dialog.component'

@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatBadgeModule,
    MatPaginatorModule,
    MatCardModule,
    MatButtonModule,
    MatSnackBarModule,
    RouterModule,
    SharedComponentsModule,
    MatFormFieldModule,
    BasedataModule
  ],
  declarations: [
    NotificationBellComponent,
    UserInfoComponent,
    VersionInfoSnackbarComponent,
    FeedbackSnackbarComponent,
    UserReleaseNotesComponent,
    UserReleaseNotesDialogComponent
  ],
    exports: [
        NotificationBellComponent,
        UserInfoComponent,
        VersionInfoSnackbarComponent,
        FeedbackSnackbarComponent,
        UserReleaseNotesComponent
    ]
})
export class HeaderModule {
}
