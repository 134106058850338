import {Component, OnInit} from '@angular/core';
import {VersionInfoService} from '../../services/repository/version-info.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-version-info-snackbar',
  templateUrl: './feedback-snackbar.component.html',
  styleUrls: ['./feedback-snackbar.component.scss']
})
export class FeedbackSnackbarComponent implements OnInit {

  timeboxAppBuildVersion = this.versionService.timeboxAppBuildVersion;
  mail: string;

  constructor(private versionService: VersionInfoService,
              private matSnackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.buildEmailTemplate();
  }

  dismiss(): void {
    this.matSnackBar.dismiss();
  }

  private buildEmailTemplate(): void {
    const newline = '%0D%0A';
    const body = 'Betrifft Version: ' + this.timeboxAppBuildVersion + newline + newline + 'Hallo Basisteam,';
    const subject = 'Feedback Timebox';
    const recipient = 'mailto:team.basis@compeople.de';
    this.mail = recipient + '?subject=' + subject + '&body=' + body;
  }

}
