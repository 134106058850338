import {ChangeDetectorRef, Component, OnInit} from '@angular/core'
import {VersionInfoService} from '../../services/repository/version-info.service'
import {MatSnackBar} from '@angular/material/snack-bar'
import {Subscription} from 'rxjs'

@Component({
  selector: 'app-version-info-snackbar',
  templateUrl: './version-info-snackbar.component.html',
  styleUrls: ['./version-info-snackbar.component.scss']
})
export class VersionInfoSnackbarComponent implements OnInit {

  timeboxAppVersion: string
  timeboxAppBuildVersion = this.versionService.timeboxAppBuildVersion
  serverVersion: string
  serverCommit: string
  serverDate: string
  serverBuild: string
  versionServiceSubscription: Subscription

  constructor(private versionService: VersionInfoService,
              private matSnackBar: MatSnackBar,
              private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.timeboxAppVersion = this.versionService.timeboxAppVersion

    this.versionServiceSubscription = this.versionService.info$.subscribe(result => {
      if (result != null) {
        this.serverVersion = result.version
        this.serverCommit = result.commit
        this.serverDate = result.time
        this.serverBuild = result.build
        this.cd.markForCheck()
      }
    })
  }

  dismiss(): void {
    this.versionServiceSubscription.unsubscribe()
    this.matSnackBar.dismiss()
  }

}
